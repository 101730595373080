import { navigate } from "vike/client/router";
import { localeAware } from "~/utils/localeAware";

type NavigateArgs = Parameters<typeof navigate>;

export function useNavigate() {
  const { withLocale } = localeAware(usePageContext());

  return (path: NavigateArgs[0], options?: NavigateArgs[1]) =>
    navigate(withLocale(path), options);
}
