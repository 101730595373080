export function useCurrentPath() {
  const pageContext = usePageContext();

  return computed(() => {
    const pathname = pageContext.urlPathname;
    if (["de", "en"].some((locale) => pathname.startsWith(`/${locale}/`))) {
      return "/" + pathname.split("/").slice(2).join("/");
    }

    return pathname;
  });
}
