import ky, { HTTPError } from "ky";
import type { PageContext } from "vike/types";
import { hasInjectionContext } from "vue";

/**
 * Our server should act as a proxy for the client, so we need to forward cookies and set proxy headers
 */
function serverHeaders(pageContext: PageContext) {
  const { cookies, envs } = pageContext;
  const baseUrl = new URL(envs.baseUrl);
  const host = baseUrl.host;
  const protocol = baseUrl.protocol.replace(":", "");

  const authCookies = Object.entries(cookies ?? []).filter(
    ([name]) =>
      name.startsWith("XSRF-TOKEN") ||
      name.startsWith("lexmea_session") ||
      name.startsWith("remember_web")
  );

  const cookieString = authCookies
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("; ");

  return {
    Cookie: cookieString,
    Origin: envs.baseUrl,
    Referrer: envs.baseUrl,
    "X-Forwarded-Host": host,
    "X-Forwarded-Proto": protocol,
  };
}

export function buildApiClient(pageContext?: PageContext) {
  if (!pageContext && !hasInjectionContext()) {
    throw new Error(
      "No pageContext provided and no injection context available"
    );
  }
  const ctx = pageContext ?? usePageContext();
  const prefixUrl = import.meta.env.SSR
    ? process.env.VITE_API_SSR_HOST
    : ctx.envs.apiBaseUrl;

  const additionalHeaders = import.meta.env.SSR ? serverHeaders(ctx) : {};
  const originalClientToken = import.meta.env.SSR
    ? ctx.cookies?.["XSRF-TOKEN"]
    : undefined;

  return ky.create({
    prefixUrl,
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
    retry: {
      limit: 2,
      statusCodes: [408, 413, 419, 429, 500, 502, 503, 504, 522, 524],
      methods: [
        "get",
        "post",
        "patch",
        "put",
        "delete",
        "head",
        "trace",
        "options",
      ],
      afterStatusCodes: [413, 429, 503],
      delay: (attemptCount) => 0.3 * 2 ** (attemptCount - 1) * 1000,
    },
    hooks: {
      beforeRequest: [
        (request) => {
          let token: string | undefined;
          if (import.meta.env.SSR) {
            token = originalClientToken;
          } else {
            token = document.cookie
              .split("; ")
              .find((row) => row.startsWith("XSRF-TOKEN="))
              ?.split("=")[1];
          }

          let headers = {
            "Accept-Language": ctx.locale,
            "X-Requested-With": "XMLHttpRequest",
            "X-XSRF-TOKEN": token ? decodeURIComponent(token) : undefined,
          };

          headers = {
            ...headers,
            ...additionalHeaders,
          };

          Object.entries(headers)
            .filter(([, value]) => Boolean(value))
            .forEach(([key, value]) => request.headers.set(key, value!));

          return request;
        },
      ],
      beforeRetry: [
        async ({ error }) => {
          if (
            !import.meta.env.SSR &&
            error instanceof HTTPError &&
            error.response.status === 419
          ) {
            await ky("api/sanctum/csrf-cookie", {
              prefixUrl,
              credentials: "include",
            });
          }
        },
      ],
    },
  });
}
