import { localeAware } from "~/utils/localeAware";

export function useWithLocale(
  value: MaybeRefOrGetter<string>,
  overwriteLocale?: MaybeRefOrGetter<"de" | "en" | undefined>
) {
  const pageContext = usePageContext();
  const { withLocale } = localeAware(pageContext);

  return computed(() => {
    const unreffed = toValue(value);
    // do not rewrite absolute URLs
    if (!unreffed.startsWith("/")) {
      return unreffed;
    }

    const l = toValue(overwriteLocale);
    if (l) {
      // assume we always have a locale in the URL
      const withoutCurrentLocale = unreffed.split("/").slice(2).join("/");
      return `/${l}/${withoutCurrentLocale}`;
    }

    return withLocale(toValue(value));
  });
}
