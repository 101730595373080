import type { ILegalText } from "~/models/ILegalText";
import type { ILegalTextParagraph } from "~/models/ILegalTextParagraph";
import type { IPaginate } from "~/models/IPaginate";
import { LexmeaApiClient } from "./apiClient";
import type { ISimplePaginate } from "~/models/ISimplePaginate";

export type SearchParagraph = ILegalTextParagraph & {
  legal_text: ILegalText;
  text_highlight: string;
};

export class LegalTextParagraphClient extends LexmeaApiClient {
  allParagraphsForLegalText = (legalTextId: number, page?: number) => {
    return this.get<ISimplePaginate<ILegalTextParagraph>>(
      `api/legalText/${legalTextId}/legalTextParagraph/paginate`,
      {
        searchParams: page ? { page: page.toString() } : undefined,
      }
    );
  };

  fetchParagraphBySlug = (legalTextSlug: string, slug: string) => {
    return this.get<ILegalTextParagraph>(
      `api/legalText/${legalTextSlug}/legalTextParagraph`,
      {
        searchParams: { slug },
      }
    );
  };

  fetchParagraphById = (paragraphId: number) => {
    return this.get<ILegalTextParagraph>(
      `api/legalTextParagraph/${paragraphId}`
    );
  };

  searchParagraphs = (query: string, legalTextId?: number) => {
    const searchParams = new URLSearchParams();
    searchParams.append("query", query);
    if (legalTextId) searchParams.append("legalTextId", legalTextId.toString());

    return this.get<IPaginate<SearchParagraph>>(
      "api/legalTextParagraph/search",
      {
        searchParams,
      }
    );
  };

  textSearch = (query: string, legalTextId: number) => {
    return this.get<
      {
        model: ILegalTextParagraph;
        highlight: { text: string[] };
      }[]
    >(`api/legalText/${legalTextId}/legalTextParagraph/textSearch`, {
      searchParams: { query },
    });
  };
}
