import { routes } from "~/routes";

export interface LexmeaLink {
  href: string;
  title: string;
}

export function useLexmeaLinks() {
  const { t } = useI18n();
  const {
    privacyNotice,
    termsAndConditions,
    imprint,
    faq,
    intro,
    vision,
    team,
    schemas,
    updates,
  } = routes;

  const hrefToLinkItem = (href: string) => ({
    href,
    title: t(`info_pages.headers.${href}.title`),
  });

  const infoItems = useArrayMap([intro, vision, team, faq], hrefToLinkItem);

  const footerItems = useArrayMap(
    [termsAndConditions, privacyNotice, imprint],
    hrefToLinkItem
  );

  const miscItems = useArrayMap(
    [termsAndConditions, privacyNotice, imprint, schemas, updates],
    hrefToLinkItem
  );

  return {
    infoItems,
    miscItems,
    footerItems,
  };
}
