export function useCanonicalUrl() {
  const { active } = useFetchActiveEntry();

  const baseUrl = useBaseUrl();
  const currentPath = useCurrentPath();
  const path = useWithLocale(() => {
    if (
      active.value &&
      active.value.type === "schema" &&
      active.value.curated
    ) {
      return active.value.canonical_path + "/" + active.value.slug;
    }

    return currentPath.value;
  });

  return computed(() => {
    return new URL(path.value, baseUrl);
  });
}
