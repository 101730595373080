export function useIsActivePath(path: MaybeRefOrGetter<string>) {
  const currentPath = useCurrentPath();

  const localizedPath = useWithLocale(path);
  const localizedPathname = useWithLocale(currentPath);

  return computed(() =>
    localizedPathname.value.startsWith(localizedPath.value.split("?")[0])
  );
}
