import { type Composer, createI18n, type Locale } from "vue-i18n";
import { nextTick } from "vue";

// export const SUPPORT_LOCALES = ['en', 'de'] as const

export async function setupI18n(initialLocale: "de" | "en" = "de") {
  const messages = await import(`./${initialLocale}-locale.ts`);
  return createI18n({
    locale: initialLocale,
    fallbackLocale: "de",
    legacy: false,
    messages: {
      [initialLocale]: messages.default,
    },
  });
}

export async function loadLocaleMessages(i18n: Composer, locale: Locale) {
  // load locale messages with dynamic import
  const messages = await import(`./${locale}-locale.ts`);

  // set locale and locale message
  i18n.setLocaleMessage(locale, messages.default);

  return nextTick();
}
