import type { INewUserEntry } from "~/models/IUserEntry";
import type { LocalSyncPayload } from "./useEntries";
import { routes } from "~/routes";

export function useLocalEntries() {
  const entries = useLocalStorage<LocalSyncPayload<INewUserEntry>[]>(
    "local-entries",
    [],
    {
      initOnMounted: true,
    }
  );

  const { workspace } = routes;

  const addFakeProperties = (entry: LocalSyncPayload<INewUserEntry>) => {
    if (entry.data.type === "schema") {
      // fake canonical_path
      entry.data.canonical_path = `${workspace}/${entry.data.legal_text_paragraph.legal_text.slug}/${entry.data.legal_text_paragraph.slug}`;
      entry.data.curated = 0;

      if (!entry.data.text) {
        entry.data.text = "";
      }
    }
    return entry;
  };

  return computed({
    get() {
      return entries.value.map((entry) => addFakeProperties(entry));
    },
    set(value) {
      entries.value = value.map((entry) => addFakeProperties(entry));
    },
  });
}
