import { LexmeaApiClient } from "~/api/apiClient";
import type { ISchema } from "~/models/IUserEntry";
import type {
  IBasicChannelInfo,
  IChannel,
  IOwnedChannel,
  ISubscribedChannel,
} from "~/models/teamMode/IChannel";
import type CreateChannelSubscriptionDTO from "~/dto/teamMode/createChannelSubscriptionDTO";
import type CreateChannelDTO from "~/dto/teamMode/createChannelDTO";
import type { IChannelSubscription } from "~/models/teamMode/IChannelSubscription";

export class TeamModeClient extends LexmeaApiClient {
  searchUser = (name: string): Promise<IBasicChannelInfo[]> => {
    return this.get("api/search/user", {
      searchParams: {
        query: name,
      },
    });
  };

  shareSchema = (data: {
    schemaId: number;
    channelIds: number[];
  }): Promise<ISchema> => {
    return this.post(`api/schema/${data.schemaId}/share`, {
      channels: data.channelIds,
    });
  };

  detachSchemaFromChannel = (data: {
    schemaId: number;
    channelId: number;
  }): Promise<void> => {
    return this.delete(
      `api/channel/${data.channelId}/schemas/${data.schemaId}/detach`
    );
  };

  getChannelSubscriptions = (): Promise<ISubscribedChannel[]> => {
    return this.get("api/channel/subscription");
  };

  deleteChannelSubscription = (data: {
    subscriberId: number;
  }): Promise<void> => {
    return this.delete(`api/subscription/${data.subscriberId}`);
  };

  subscribeChannel = (
    data: CreateChannelSubscriptionDTO
  ): Promise<ISubscribedChannel> => {
    const { channelId, ...rest } = data;
    return this.post(`api/channel/${channelId}/subscription`, rest);
  };

  updateSubscription = (options: {
    subscriptionId: number;
    data: Partial<
      Pick<IChannelSubscription, "email_notification_allowed" | "color">
    >;
  }): Promise<IChannelSubscription> => {
    return this.patch(`api/subscription/${options.subscriptionId}`, {
      ...options.data,
    });
  };

  listChannels = (): Promise<IOwnedChannel[]> => {
    return this.get("api/channel");
  };

  getChannel = (channelId: number): Promise<IChannel> => {
    return this.get(`api/channel/${channelId}`);
  };

  getBasicChannel = (channelId: number): Promise<IBasicChannelInfo> => {
    return this.get(`api/channel/${channelId}/info`);
  };

  createChannel = (data: CreateChannelDTO): Promise<IOwnedChannel> => {
    return this.post("api/channel", { ...data });
  };

  updateChannel = (options: {
    channelId: number;
    data: Partial<CreateChannelDTO>;
  }): Promise<IOwnedChannel> => {
    return this.patch(`api/channel/${options.channelId}`, { ...options.data });
  };

  deleteChannel = (channelId: number): Promise<void> => {
    return this.delete(`api/channel/${channelId}`);
  };
}
