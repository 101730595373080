import type { QueryClientConfig } from "@tanstack/vue-query";

export default {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // 5 minutes
      staleTime: 5 * 60 * 1000,
    },
  },
} satisfies QueryClientConfig;
