<template>
  <a
    ref="element"
    :href="href"
    :class="[props.class, active ? activeClass : inactiveClass]"
  >
    <slot :active="active" />
  </a>
</template>

<script setup lang="ts">
type Props = {
  activeClass?: string;
  inactiveClass?: string;
  class?: string | string[] | Record<string, boolean | undefined | null>;
  href: string;
  locale?: "de" | "en";
};

const props = defineProps<Props>();

const element = useTemplateRef<HTMLAnchorElement>("element");

defineExpose({ element });

const href = useWithLocale(
  () => props.href,
  () => props.locale
);

const active = useIsActivePath(href);
</script>
