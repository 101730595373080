import FloatingVue from "floating-vue";
import Toast from "vue-toastification";
import { MotionPlugin } from "@vueuse/motion";
import { setupI18n } from "~/i18n";
import { createVfm } from "vue-final-modal";
import type { OnCreateAppAsync } from "vike-vue/types";
import { createUnhead } from "~/pages/createUnhead";
import { sentryBrowserConfig } from "../../sentry.browser.config";
import { UAParser } from "ua-parser-js";
import { provideSSRWidth } from "@vueuse/core";
import type { PageContext } from "vike/types";

export const onCreateApp: OnCreateAppAsync = async (
  pageContext
): ReturnType<OnCreateAppAsync> => {
  const { app } = pageContext;

  const { sentryDsn, sentryRelease, environment, apiBaseUrl } =
    pageContext.envs;

  sentryBrowserConfig({
    app,
    dsn: sentryDsn,
    release: sentryRelease,
    environment,
    apiUrl: apiBaseUrl,
  });

  const i18n = await setupI18n(pageContext.locale);

  const vfm = createVfm();
  const head = createUnhead();

  setupInitialWidth(pageContext);

  app
    .use(head)
    .use(Toast, {
      containerClassName: "mt-12",
      toastClassName: "shadow-lg",
      hideProgressBar: true,
      transition: "Vue-Toastification__fade",
      transitionDuration: 150,
    })
    .use(MotionPlugin, {
      directives: {
        "lexmea-slide-top": {
          initial: {
            y: -100,
            opacity: 0,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 225,
              duration: 300,
            },
          },
        },
        "lexmea-slide-left": {
          initial: {
            x: -100,
            opacity: 0,
          },
          visibleOnce: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 225,
              duration: 300,
            },
          },
        },
        "lexmea-slide-right": {
          initial: {
            x: 100,
            opacity: 0,
          },
          visibleOnce: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 225,
              duration: 300,
            },
          },
        },
      },
    })
    .use(FloatingVue, { container: "#teleported" })
    .use(i18n)
    .use(vfm);

  app.provide(queriesKey, createQueries(pageContext));
};

function setupInitialWidth(pageContext: PageContext): void {
  const headers = import.meta.env.SSR ? pageContext.headers : null;
  if (!headers) return;

  const userAgentString = headers["user-agent"];
  const { device } = UAParser(userAgentString);

  const width = device.is("mobile") ? 425 : 1024;

  provideSSRWidth(width, pageContext.app);
}
