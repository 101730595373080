import { useHead } from "@unhead/vue";

export default function useSeoHead() {
  const canonical = useCanonicalUrl();
  const pageContext = usePageContext();

  useHead({
    templateParams: {
      schemaOrg: {
        host: canonical.value.host,
        path: canonical.value.pathname,
        inLanguage: pageContext.locale,
      },
    },
  });
}
