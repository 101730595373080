<template>
  <label class="relative block" :class="wrapperClass">
    <LexmeaHoverLabel
      v-if="label"
      :active="focused || !!model || active"
      :focused="focused"
      :class="{ 'ml-8': type === 'search' }"
      :label="label"
      :required="required"
    />
    <span class="block">
      <span
        v-if="type === 'search'"
        class="absolute left-3 flex h-full items-center"
      >
        <img :src="search" class="h-4 w-4" :alt="$t('ui.search.title')" />
      </span>
      <input
        ref="inputRef"
        v-model="model"
        class="w-full rounded border-none outline-none ring-1 ring-lexmea-gray-700 transition-all placeholder:text-center placeholder:text-sm placeholder:font-medium placeholder:text-gray-400 focus:ring-lexmea-selection-darker"
        :class="{
          'border border-maroon-flush': error.length > 0,
          'ring-0': borderless,
          '!ring-lexmea-pop-darker': active,
          'pr-10': type === 'password',
          'pl-10 pr-8': type === 'search',
        }"
        :type="type === 'password' ? visibility : type"
        :required="required"
        :maxlength="maxlength"
        :spellcheck="type !== 'password'"
        v-bind="$attrs"
        @focus="focused = true"
        @blur="focused = false"
      />
      <Transition name="fade">
        <button
          v-if="type === 'search'"
          :class="{ 'opacity-0': !model?.toString().length }"
          class="absolute right-3 top-0 flex h-full items-center"
          type="button"
          title="Suche löschen"
          @click="model = ''"
        >
          <span class="sr-only">Suche löschen</span>
          <i-heroicons-x-mark-20-solid class="h-4 w-4" aria-hidden="true" />
        </button>
      </Transition>
      <button
        v-if="type === 'password'"
        type="button"
        role="switch"
        :aria-checked="visibility === 'text'"
        class="absolute inset-y-0 right-3 top-2 flex size-6 items-center text-gray-400"
        :title="
          visibility === 'text' ? 'Passwort verstecken' : 'Passwort anzeigen'
        "
        @click="visibility === 'text' ? hidePassword() : showPassword()"
      >
        <Transition name="scale" mode="out-in">
          <i-heroicons-eye-20-solid
            v-if="visibility === 'text'"
            class="size-full"
            aria-hidden="true"
          />
          <i-heroicons-eye-slash-20-solid
            v-else-if="visibility === 'password'"
            class="size-full"
            aria-hidden="true"
          />
        </Transition>
      </button>
    </span>
    <Transition name="scale">
      <span v-if="error.length > 0" class="text-sm text-maroon-flush">
        {{ typeof error === "string" ? error : error[0] }}
      </span>
    </Transition>
  </label>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import search from "~/assets/landingpage/icon_search_lg.svg";

const props = withDefaults(
  defineProps<{
    error?: string | string[];
    label?: string;
    modelValue?: string;
    required?: boolean;
    active?: boolean;
    type?: string;
    borderless?: boolean;
    maxlength?: string;
    wrapperClass?: string;
  }>(),
  {
    error: "",
    modelValue: "",
    label: "",
    maxlength: undefined,
    type: "text",
    borderless: false,
    wrapperClass: "",
  }
);

defineEmits<{
  "update:modelValue": [data: string];
}>();

const inputRef = useTemplateRef("inputRef");

defineExpose({
  focus: () => inputRef.value?.focus(),
});

const model = useVModel(props);
const focused = ref(false);
const visibility = ref<"password" | "text">("password");

const showPassword = () => {
  visibility.value = "text";
};

const hidePassword = () => {
  visibility.value = "password";
};
</script>
<style scoped>
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>
