<template>
  <LocaleLink
    v-slot="{ active }"
    :href="libraryURL"
    :title="$t('library')"
    class="flex gap-2"
  >
    <slot :active="active" />
  </LocaleLink>
</template>

<script setup lang="ts">
import { routes } from "~/routes";

const { library } = routes;
const currentPath = useCurrentPath();

const lastSubPagePersisted = useLocalStorage<string | null>(
  "last-visited-library-subpage",
  null,
  {
    initOnMounted: true,
  }
);

function setLastSubPagePersisted(subpage: string) {
  if (
    subpage === lastSubPagePersisted.value ||
    !subpage.startsWith(library.base) ||
    subpage === library.base
  ) {
    return;
  }
  lastSubPagePersisted.value = subpage;
}

watchEffect(() => {
  setLastSubPagePersisted(currentPath.value);
});

const libraryURL = computed(() => lastSubPagePersisted.value || library.laws);
</script>
