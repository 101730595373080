import type { OnBeforeRenderClientSync } from "vike-vue/types";
import posthog from "posthog-js";

function registerPosthog(key: string) {
  posthog.init(key, {
    api_host: "/ph/ingest",
    ui_host: "https://eu.posthog.com",
    persistence: "memory",
  });

  return posthog;
}

export const onBeforeRenderClient: OnBeforeRenderClientSync = ({
  app,
  envs,
}): ReturnType<OnBeforeRenderClientSync> => {
  if (!import.meta.env.PROD) {
    return;
  }
  const ph = registerPosthog(envs.posthogKey);
  app!.provide(envs.posthogKey, ph);

  const { transitionStartHook, transitionEndHook } = usePageTransition();
  transitionStartHook.on(() => {
    ph.capture("$pageview");
  });
  transitionEndHook.on(() => {
    ph.capture("$pageleave");
  });
};
