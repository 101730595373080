import { breakpointsTailwind } from "@vueuse/core";

export default function useLexMeaTailwindBreakpoints() {
  const mounted = useMounted();
  const breakpoints = useBreakpoints(breakpointsTailwind);
  const desktopBreakpoint = "lg";
  const tabletBreakpoint = "md";
  const mobileBreakpoint = "sm";

  const makeBreakpoint = (breakpoint: any) =>
    logicOr(logicNot(mounted), breakpoints.smaller(breakpoint));

  return {
    isSmallerThanSm: makeBreakpoint(mobileBreakpoint),
    isSmallerThanMd: makeBreakpoint(tabletBreakpoint),
    isSmallerThanLg: makeBreakpoint(desktopBreakpoint),
  };
}
